<template>
    <MarketingBarTimer v-bind="propsToBind" />
</template>

<script>
import { MARKETING_BAR_ALLOWED_ICONS } from '@configs/marketing-bar';

import { getProps } from '@modules/page-builder/helpers/component';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

import MarketingBarTimer from '@marketing-bar-components/organisms/MarketingBarTimer/MarketingBarTimer';

export default {
    name: 'CmsMarketingBarTimer',

    components: {
        MarketingBarTimer,
    },

    extends: CmsBaseComponent,

    props: {
        ...getProps(MarketingBarTimer),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    date: '',
                    color: '#fff',
                    textDesktop: '',
                    textMobile: '',
                    textLink: '',
                    backgroundColor: '',
                    icon: '',
                    iconColor: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
                icon: [...Object.values(MARKETING_BAR_ALLOWED_ICONS), ''],
            },

            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                textMobile: {
                    max: 40,
                },

                textDesktop: {
                    max: 80,
                },

                date: {
                    hint: 'Date format is YYYY-MM-DD HH:mm',
                },
            },
        };
    },
};
</script>
