<template>
    <CustomProductsWrapper
        #default="{ products }"
        class="custom-products-simple-slider-wrapper"
        :product-skus="productSkus"
    >
        <ProductsSimpleSlider :items-count="itemsCount" :products="products" />
    </CustomProductsWrapper>
</template>

<script>
import CustomProductsWrapper from '@organisms/CustomProductsWrapper/CustomProductsWrapper';
import ProductsSimpleSlider from '@molecules/ProductsSimpleSlider/ProductsSimpleSlider';
import { ITEMS_COUNT } from '@configs/simple-slider';

export default {
    name: 'CustomProductsSimpleSliderWrapper',

    components: {
        CustomProductsWrapper,
        ProductsSimpleSlider,
    },

    props: {
        productSkus: {
            type: Array,
            default: () => [],
        },

        itemsCount: {
            type: Number,
            default: 6,
            validator: value => ITEMS_COUNT.includes(value),
        },
    },
};
</script>

<style lang="scss" scoped>
$min-height-mobile: 380px;
$min-height-lg-min: 374px;
$min-height-container: 510px;

$multiplier: 100px / 22px;

@mixin min-height-lg-min($min-height, $multiplier) {
    min-height: calc(#{$min-height} + ((100vw - 1024px) / #{$multiplier}));
}

.custom-products-simple-slider-wrapper {
    @apply flex items-center;
    min-height: $min-height-mobile;

    &.is-loading {
        @apply bg-gray4;
    }

    @screen lg {
        @include min-height-lg-min($min-height-lg-min, $multiplier);
    }

    @screen container {
        min-height: $min-height-container;
    }
}
</style>
