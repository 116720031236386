<template>
    <div class="products-simple-slider">
        <ContainerContent class="slider-container">
            <BaseHeading v-if="heading" class="heading">
                {{ heading }}
            </BaseHeading>

            <SimpleSlider
                :items-count="itemsCount"
                :additional-scroll-offset="0"
                :navigation-buttons-styles="navigationButtonsStyles"
                class="slider"
                :class="{ 'with-heading': heading }"
                :has-navigation-buttons="hasNavigationButtons"
                :change-slide-by-one="false"
            >
                <template #slides>
                    <ProductsSimpleSliderSlide
                        v-for="(product, index) in products"
                        :key="`products-simple-slider-slide-${product.sku}-${index}`"
                        :product="product"
                        :items-count="itemsCount"
                        :index="index"
                        class="item"
                    />
                </template>
            </SimpleSlider>
        </ContainerContent>
    </div>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import ProductsSimpleSliderSlide from '@molecules/ProductsSimpleSlider/ProductsSimpleSliderSlide';
import SimpleSlider from '@molecules/SimpleSlider/SimpleSlider';
import { ITEMS_COUNT } from '@configs/simple-slider';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

const NAVIGATION_BUTTON_WIDTH = 48;

export default {
    name: 'ProductsSimpleSlider',

    components: {
        SimpleSlider,
        ProductsSimpleSliderSlide,
        ContainerContent,
        BaseHeading,
    },

    props: {
        heading: {
            type: String,
            default: '',
        },

        products: {
            type: Array,
            default: () => [],
        },

        itemsCount: {
            type: Number,
            default: 6,
            validator: value => ITEMS_COUNT.includes(value),
        },
    },

    computed: {
        percentSlideWidth() {
            return 100 / this.itemsCount;
        },

        navigationButtonsStyles() {
            return {
                right: `calc(${this.percentSlideWidth}% - ${NAVIGATION_BUTTON_WIDTH}px)`,
            };
        },

        hasNavigationButtons() {
            return this.products.length > this.itemsCount;
        },
    },
};
</script>

<style lang="scss" scoped>
$container-with-slider-max-width: 1602px;
$shadow-width: 3px;

$product-card-description-height: 139px;
$scrollbar-height: $tailwindcss-spacing-1;

$navigation-buttons-width: $tailwindcss-spacing-7 * 2;
$navigation-buttons-height: $tailwindcss-spacing-8;

$slider-x-padding: $tailwindcss-spacing-3 - $shadow-width;
$wrapper-padding-bottom: $tailwindcss-spacing-4;

.products-simple-slider {
    @apply w-full;

    .heading {
        @apply text-m leading-m px-3 w-full;
    }

    &:deep() {
        .slider-container {
            @apply px-0;
        }

        .slider {
            &.with-heading {
                @apply mt-2;
            }
        }
    }

    @screen lg {
        .heading {
            @apply text-xl leading-xl px-5;
        }

        &:deep() {
            .slider-container {
                max-width: calc(
                    #{$container-with-slider-max-width} + 2 * #{$shadow-width}
                );
            }

            .slider {
                &.with-heading {
                    @apply mt-3;
                }
            }

            .slider-container-content {
                padding-left: $slider-x-padding;
                padding-right: $slider-x-padding;
            }

            .navigation-buttons {
                @apply flex;
                top: calc(
                    (
                            100% - #{$product-card-description-height} - #{$scrollbar-height} -
                                #{$wrapper-padding-bottom}
                        ) / 2 - #{$navigation-buttons-height / 2}
                );
            }
        }
    }
}
</style>
