<template>
    <div class="product-card" :class="{ 'with-badges': productHasBadges }">
        <ButtonIconToggle
            v-if="hasWishListButton"
            :variant="FAVOURITE_VARIANT"
            :size="FAVOURITE_SIZE"
            :disabled="isLoading"
            :data-test-id="ADD_TO_WISHLIST"
            :toggled="isAdded"
            class="add-to-wishlist"
            @toggle="onWishlistToggle()"
        />
        <component
            :is="linkComponent"
            :path="targetPath"
            :label="product.nameDisplay"
            :title="product.nameDisplay"
            :disabled="isLinkDisabled"
            :target="isModivoProduct ? '_blank' : null"
            class="product-card-link"
            @click.exact.native="
                $emit('product-click', { product, mouseEvent: $event })
            "
            @click.ctrl.exact.native="
                $emit('product-click', { product, mouseEvent: $event })
            "
            @click.exact.native.middle="
                $emit('product-click', {
                    product,
                    mouseEvent: { ...$event, middleKey: true },
                })
            "
            @contextmenu.native="$emit('product-open-contextmenu', { product })"
        >
            <div class="picture-switcher">
                <BasePicture
                    :src="picture.url"
                    :alt="picture.alt || ''"
                    :is-lazy="isLazy"
                    :fetch-priority="fetchPriority"
                    :sources="picture.sources"
                    :width="product.pictureWidth || pictureWidth"
                    :height="product.pictureHeight || pictureHeight"
                    :index="product.sku"
                    :sizes="pictureSizes"
                    class="product-image"
                    @mouseenter="isMobile ? null : (switchPicture = true)"
                    @mouseleave="switchPicture = false"
                />
            </div>

            <div v-if="$slots.productVariantPicture" class="variant-image">
                <slot name="productVariantPicture" />
            </div>

            <ProductBadges
                v-if="productHasBadges"
                :badges="product.badges"
                class="badges"
            />

            <div class="name-container">
                <strong class="brand-name">
                    {{ product.brandName }}
                </strong>
                <h2 class="product-name">
                    {{ product.nameDisplay }}
                </h2>
            </div>
            <ProductPrice
                class="price"
                :display="priceDisplay"
                :price-regular="product.price.regular.formatted"
                :price-regular-per-unit="product.price.regular.formatted"
                :price-promotional="product.price.promotional.formatted"
                :is-promotional-lower-than-omnibus="
                    isPromotionalLowerThanOmnibus
                "
                :price-omnibus="omnibusPrice"
                :discount="product.price.discount"
                :is-with-marketing-action="hasPromoAction"
            />
            <span v-if="isSponsoredTextVisible" class="product-card-sponsored">
                {{ $t('Sponsored') }}
            </span>
        </component>

        <LazyHydrate :never="true">
            <div v-if="hasPromoAction" class="promo-labels">
                <PromotionLabel
                    v-if="hasPromoAction"
                    :promo-label="promoAction"
                />
            </div>
        </LazyHydrate>
    </div>
</template>

<script>
import LazyHydrate from '@assets/vue-lazy-hydration/LazyHydrate';

import { MODIVO_DOMAIN_NAME } from '@localeConfig/keys';

import {
    IMAGE_TYPE_PRODUCT_256w_256h,
    IMAGE_TYPE_PRODUCT_276w_368h,
    IMAGE_TYPE_PRODUCT_512w_512h,
    IMAGE_TYPE_PRODUCT_528w_704h,
    PRODUCT_IMAGE_TYPES,
} from '@types/Image';
import { THEME_PURE } from '@types/ButtonIcon';
import { ADD_TO_WISHLIST } from '@types/AutomaticTestIDs';

import { getProductImage, isSliderImagesTestEnabled } from '@assets/images';

import ProductCard from '@mixins/ProductCard';
import FetchPriority from '@mixins/FetchPriority';
import AddToWishlist from '@mixins/AddToWishlist';

import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import BasePicture from '@atoms/BasePicture/BasePicture';

import ProductBadges from '@molecules/ProductBadges/ProductBadges';
import ProductPrice from '@molecules/ProductPrice/ProductPrice';
import PromotionLabel from '@molecules/PromotionLabel/PromotionLabel';

import {
    ButtonIconToggle,
    BUTTON_ICON_TOGGLE_VARIANTS,
    BUTTON_ICON_TOGGLE_SIZES,
} from '@eobuwie-ui/components/ButtonIconToggle/v1';

export default {
    name: 'ProductCard',

    components: {
        LazyHydrate,
        BasePicture,
        PromotionLabel,
        ProductBadges,
        ButtonIconToggle,
        ProductPrice,
        BaseLink,
    },

    mixins: [ProductCard, AddToWishlist, FetchPriority],

    props: {
        promoAction: {
            type: Object,
            default: () => ({}),
        },

        hasPromoAction: {
            type: Boolean,
            default: false,
        },

        isModivoProduct: {
            type: Boolean,
            default: false,
        },

        // eslint-disable-next-line vue/no-unused-properties
        actionField: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            switchPicture: false,
        };
    },

    computed: {
        omnibusPrice() {
            return this.product.price.omnibus?.formatted;
        },

        imageDimensions() {
            return isSliderImagesTestEnabled(this.$abTests, this.$route)
                ? [IMAGE_TYPE_PRODUCT_276w_368h, IMAGE_TYPE_PRODUCT_528w_704h]
                : [IMAGE_TYPE_PRODUCT_256w_256h, IMAGE_TYPE_PRODUCT_512w_512h];
        },

        pictureWidth() {
            return isSliderImagesTestEnabled(this.$abTests, this.$route)
                ? PRODUCT_IMAGE_TYPES[IMAGE_TYPE_PRODUCT_276w_368h].width
                : PRODUCT_IMAGE_TYPES[IMAGE_TYPE_PRODUCT_256w_256h].width;
        },

        pictureHeight() {
            return isSliderImagesTestEnabled(this.$abTests, this.$route)
                ? PRODUCT_IMAGE_TYPES[IMAGE_TYPE_PRODUCT_276w_368h].height
                : PRODUCT_IMAGE_TYPES[IMAGE_TYPE_PRODUCT_256w_256h].height;
        },

        imageType() {
            return isSliderImagesTestEnabled(this.$abTests, this.$route)
                ? IMAGE_TYPE_PRODUCT_276w_368h
                : IMAGE_TYPE_PRODUCT_256w_256h;
        },

        primaryPicture() {
            const {
                imageType,
                product: { primaryPicture },
            } = this;

            if (primaryPicture) {
                return {
                    ...primaryPicture,
                    url: primaryPicture.src,
                };
            }

            return getProductImage(
                this.product,
                0,
                this.imageDimensions,
                imageType
            );
        },

        secondaryPicture() {
            const {
                product: { secondaryPicture },
            } = this;

            if (secondaryPicture) {
                return {
                    ...secondaryPicture,
                    url: secondaryPicture.src,
                };
            }

            return getProductImage(this.product, 1, this.imageDimensions, null);
        },

        picture() {
            if (
                this.switchPicture &&
                this.secondaryPicture &&
                Object.keys(this.secondaryPicture).length
            ) {
                return this.secondaryPicture;
            }

            return this.primaryPicture;
        },

        isSponsoredTextVisible() {
            return this.product.isSponsored && this.hasSponsoredText;
        },

        productHasBadges() {
            return !!Object.keys(this.product.badges).length;
        },

        isPromotionalLowerThanOmnibus() {
            const { omnibus, promotional } = this.product.price;

            return !!omnibus?.amount && promotional.amount < omnibus?.amount;
        },

        targetPath() {
            let { url } = this.product;

            if (this.isModivoProduct) {
                const modivoDomain = this.$getLocaleConfigByKey(
                    MODIVO_DOMAIN_NAME
                );

                url = `https://${modivoDomain}${url}`;
            }

            if (!this.attributionToken) {
                return url;
            }

            return `${url}?token=${this.attributionToken}`;
        },

        linkComponent() {
            return this.isModivoProduct ? BaseLinkStatic : BaseLink;
        },
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                if (this.product.isSponsored) {
                    this.product.adTechEvents.onLoadBeacons.forEach(beacon => {
                        this.$services.adTech.notifyBeacon({ beacon });
                    });
                }
            },
        },
    },

    beforeCreate() {
        this.THEME_PURE = THEME_PURE;
        this.ADD_TO_WISHLIST = ADD_TO_WISHLIST;
        this.FAVOURITE_VARIANT = BUTTON_ICON_TOGGLE_VARIANTS.FAVOURITE;
        this.FAVOURITE_SIZE = BUTTON_ICON_TOGGLE_SIZES.S;
    },

    methods: {
        async onWishlistToggle() {
            this.$emit('wishlist-button-click', {
                product: this.product,
                isAdded: this.isAdded,
            });

            this.toggle();

            await this.$nextTick();
            this.$emit('focus');
        },
    },
};
</script>

<style lang="scss" scoped>
$name-line-height: theme('lineHeight.s');
$visible-badges-number-mobile: 2;

.product-card {
    @apply flex flex-col flex-nowrap relative w-full mx-auto;

    max-width: theme('customVariables.productCard.maxWidth');

    .content {
        @apply flex flex-wrap flex-col items-center justify-center w-full;
    }

    .extend-info {
        @apply w-full hidden;
    }

    .add-to-wishlist {
        @apply absolute top-0 right-0 z-1;
    }

    .product-card-link {
        @apply flex flex-col justify-center items-center w-full cursor-pointer;

        &:hover {
            @apply no-underline;
        }
    }

    .picture-switcher {
        @apply w-full;
        max-width: theme('customVariables.productCard.pictureMaxWidth');
    }

    .product-card-sponsored {
        @apply text-xs leading-xs text-text3;
    }

    .badges {
        @apply my-2;
        min-height: theme('minHeight.m');
    }

    .promo-labels {
        @apply mt-2 h-4;
    }

    .variant-image {
        @apply absolute w-full top-0;
    }

    .name-container {
        @apply overflow-hidden text-center w-full;

        height: calc(#{$name-line-height} * 2);
    }

    &:not(&.with-badges) {
        .name-container {
            @apply mt-6;
        }
    }

    .brand-name,
    .product-name {
        @apply block text-s font-book;

        line-height: $name-line-height;
    }

    .brand-name {
        @apply text-text2;
    }

    .product-name {
        @apply truncate text-text3;
    }

    .price {
        @apply justify-center mt-1;

        &.price-inline {
            margin-left: calc(-1 * theme('spacing.1'));
            margin-right: calc(-1 * theme('spacing.1'));
        }
    }

    &:deep() {
        .price {
            &.price-inline {
                .price-regular,
                .price-final {
                    @apply px-1 ml-0;
                }
            }
        }
    }

    @screen mobile-only {
        &:deep() {
            .badge {
                @apply hidden;

                &:nth-child(-n + #{$visible-badges-number-mobile}) {
                    @apply inline-flex;
                }
            }
        }
    }

    @screen lg {
        .name-container {
            @apply px-3;
        }
    }
}
</style>
