<template>
    <SliderSlide
        class="products-simple-slider-slide"
        :class="`items-count-${itemsCount}`"
    >
        <ProductCard
            class="product-card"
            :product="product"
            :is-lazy="true"
            :extend-info="false"
            :has-wish-list-button="true"
            :wishlist-button-on-hover="false"
            :show-discount="true"
            :show-discount-value="true"
            :show-promo-sticker="false"
            :index="index"
            picture-sizes="256px"
            price-display="block"
        />
    </SliderSlide>
</template>

<script>
import SliderSlide from '@molecules/SliderSlide/SliderSlide';
import ProductCard from '@molecules/ProductCard/ProductCard';
import { ITEMS_COUNT } from '@configs/simple-slider';

export default {
    name: 'ProductsSimpleSliderSlide',

    components: {
        SliderSlide,
        ProductCard,
    },

    props: {
        product: {
            type: Object,
            required: true,
        },

        itemsCount: {
            type: Number,
            default: 6,
            validator: value => ITEMS_COUNT.includes(value),
        },

        index: {
            type: Number,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
$item-width-mobile: 164px;
$item-width-with-3-items: 100% / 3;
$item-width-with-4-items: 100% / 4;
$item-width-with-6-items: 100% / 6;

$shadow-width: 3px;
$hover-size: $tailwindcss-spacing-3 + $shadow-width;

.products-simple-slider-slide {
    @apply p-2;
    width: $item-width-mobile;

    &:first-child {
        @apply ml-2;
    }

    &:last-child {
        @apply pr-3;
        min-width: calc(#{$item-width-mobile} + #{$tailwindcss-spacing-2});
    }

    &:deep() {
        .product-card {
            @apply z-1 mb-0;

            .content {
                @apply p-0;

                &:before {
                    margin: -$tailwindcss-spacing-3;
                    width: calc(100% + #{$tailwindcss-spacing-5});
                    height: calc(100% + #{$tailwindcss-spacing-5});
                    box-shadow: 0 2px 5px rgba(34, 34, 34, 0.2);
                }
            }

            .add-to-wishlist {
                @apply w-7 h-7 top-0 right-0;
            }
        }
    }

    @screen lg {
        padding: $hover-size;

        &:first-child {
            @apply ml-0;
        }

        &:last-child {
            padding-right: $hover-size;
        }

        &.items-count-3 {
            min-width: $item-width-with-3-items;
        }

        &.items-count-4 {
            min-width: $item-width-with-4-items;
        }

        &.items-count-6 {
            min-width: $item-width-with-6-items;
        }
    }
}
</style>
