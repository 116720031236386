<template>
    <SliderSlide
        v-if="hasAllRequiredValues"
        :scroll-snap-on-mobile="true"
        class="marketing-influencer-slider-slide"
    >
        <div class="influencer-card">
            <div class="picture-container">
                <BasePicture
                    class="slide-image"
                    :width="image.width"
                    :height="image.height"
                    :src="image.url"
                    :alt="image.alt"
                    :sources="image.sources"
                    :is-lazy="true"
                />
            </div>

            <div class="picture-footer">
                <BasePicture
                    class="avatar"
                    :width="avatarImage.width"
                    :height="avatarImage.height"
                    :src="avatarImage.url"
                    :alt="avatarImage.alt"
                    :sources="avatarImage.sources"
                    :is-lazy="true"
                />
                <div class="influencer-info">
                    <BaseHeading class="influencer-name">
                        {{ influencerName }}
                    </BaseHeading>
                    <BaseParagraph class="products-count">
                        {{ productsCount }}
                    </BaseParagraph>
                </div>
            </div>
        </div>

        <div class="products-container">
            <CustomProductsWrapper
                #default="{ products }"
                :product-skus="productSkusArray"
            >
                <VerticalProductsSimpleSlider
                    :products="products"
                    :items-count="4"
                    slider-class="marketing-influencer-products-slider"
                />
            </CustomProductsWrapper>
        </div>
    </SliderSlide>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BasePicture from '@atoms/BasePicture/BasePicture';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import SliderSlide from '@molecules/SliderSlide/SliderSlide';
import VerticalProductsSimpleSlider from '@organisms/VerticalProductsSimpleSlider/VerticalProductsSimpleSlider';
import {
    DEFAULT_IMAGE_FORMAT,
    MARKETING_INFLUENCER_IMAGE_TYPES as IMAGE_TYPES,
    IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h,
    IMAGE_TYPE_MARKETING_AVATAR_32w_32h,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';
import { parseArrayString } from '@modules/page-builder/helpers/component';
import CustomProductsWrapper from '@organisms/CustomProductsWrapper/CustomProductsWrapper';

export default {
    name: 'MarketingInfluencerSliderSlide',

    components: {
        CustomProductsWrapper,
        BaseHeading,
        BasePicture,
        BaseParagraph,
        SliderSlide,
        VerticalProductsSimpleSlider,
    },

    props: {
        influencerName: {
            type: String,
            required: true,
        },

        influencerAvatarUrl: {
            type: String,
            required: true,
        },

        imageUrl: {
            type: String,
            required: true,
        },

        productSkus: {
            type: String,
            required: true,
        },

        productsCount: {
            type: String,
            required: true,
        },
    },

    computed: {
        hasAllRequiredValues() {
            return (
                this.influencerName &&
                this.influencerAvatarUrl &&
                this.imageUrl &&
                this.productSkus &&
                this.productsCount
            );
        },

        productSkusArray() {
            return parseArrayString(this.productSkus);
        },

        image() {
            return this.$imaginator.getImage(
                this.imageUrl,
                'marketing-influencer',
                this.influencerName,
                IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                Object.keys(IMAGE_TYPES)
            );
        },

        avatarImage() {
            return this.$imaginator.getImage(
                this.influencerAvatarUrl,
                'marketing-avatar',
                this.influencerName,
                IMAGE_TYPE_MARKETING_AVATAR_32w_32h,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [IMAGE_TYPE_MARKETING_AVATAR_32w_32h]
            );
        },
    },
};
</script>

<style lang="scss" scoped>
$container-padding-desktop: $tailwindcss-spacing-5;
$container-padding-md: $tailwindcss-spacing-3;
$slide-image-ratio: 4/3;
$slide-image-footer: 72px;
$slide-image-border-height: 2px;

$slide-image-width-lg: 1/3;
$slide-image-width-md: 1/2;

$slide-mobile-height: 128px;
$placeholder-products-number: 3;

$container-content-max-width: #{theme('container.maxWidth')};

@mixin products-slider-height(
    $container-max-width,
    $container-padding,
    $slide-image-width,
    $border-height
) {
    height: calc(
        (
                (
                        (#{$container-max-width} - (#{$container-padding} * 2)) *
                            #{$slide-image-width}
                    ) * #{$slide-image-ratio}
            ) + #{$slide-image-footer} - #{$border-height}
    );
}

$mobile-slide-padding: $tailwindcss-spacing-3;

.marketing-influencer-slider-slide {
    @apply flex flex-col shrink-0 w-full pr-3 outline-none;
    width: calc(100% - #{$mobile-slide-padding});

    &:last-of-type {
        @apply pr-5;
        flex-basis: 100%;
    }

    .slide-image {
        @apply opacity-65 rounded-t-4;
    }

    &.active {
        .slide-image {
            @apply opacity-100;
        }

        .influencer-card {
            .picture-footer {
                @apply bg-gray1;

                .influencer-name {
                    @apply text-light;
                }

                .products-count {
                    @apply text-gray4;
                }
            }
        }
    }

    .influencer-card {
        .picture-footer {
            @apply flex p-12p bg-light rounded-b-4;

            .influencer-name {
                @apply text-text2;
            }

            .products-count {
                @apply text-text3 text-r leading-r;
            }
        }

        &:deep(.avatar) {
            @apply w-5 h-5 mr-12p self-center rounded-50 overflow-hidden;
        }
    }

    .products-container {
        @apply mt-3;
    }

    &:deep() {
        .marketing-influencer-products-slider {
            @apply bg-light h-auto;
            border-bottom-width: $slide-image-border-height;
        }

        .is-loading {
            @apply bg-gray8;
        }

        .custom-products-wrapper {
            @apply block z-1;
            min-height: calc(
                #{$slide-mobile-height} * #{$placeholder-products-number}
            );
        }
    }

    @screen md {
        @apply flex-row w-full pr-0 h-auto;

        &:last-of-type {
            @apply pr-0;
        }

        .influencer-card {
            @apply w-1/2;
        }

        .products-container {
            @apply w-1/2 mt-0;
        }

        &:deep() {
            .marketing-influencer-products-slider,
            .custom-products-wrapper {
                @include products-slider-height(
                    100vw,
                    $container-padding-md,
                    $slide-image-width-md,
                    $slide-image-border-height
                );
            }

            .custom-products-wrapper {
                @apply mx-3;
            }
        }
    }

    @screen lg {
        @apply w-2/3;

        &:last-of-type {
            @apply pr-1/3;
        }

        &:deep() {
            .marketing-influencer-products-slider,
            .custom-products-wrapper {
                @include products-slider-height(
                    100vw,
                    $container-padding-desktop,
                    $slide-image-width-lg,
                    $slide-image-border-height
                );
            }
        }
    }

    @screen xl {
        &:deep() {
            .marketing-influencer-products-slider,
            .custom-products-wrapper {
                @include products-slider-height(
                    $container-content-max-width,
                    $container-padding-desktop,
                    $slide-image-width-lg,
                    $slide-image-border-height
                );
            }
        }
    }
}
</style>
