import { createNamespacedHelpers } from 'vuex';

import { PRODUCT_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { PRODUCT_SLIDER_SLIDE_ATTRIBUTE } from '@configs/product';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { IMPRESSION_VIEW } from '@analytics-types/Events';

import DataLayerProduct from '@models/Analytics/DataLayerProduct';

import { mapProductToDataLayerProduct } from '@assets/recommendations';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    data() {
        return {
            productsLoaded: [],
            productObserver: null,
            productsSliderComponent: null,
        };
    },

    watch: {
        async productsLoaded(products) {
            if (!products.length) {
                return;
            }

            await this.$nextTick();

            this.productObserver = new IntersectionObserver(
                entries => {
                    const entriesIntersecting = entries.filter(
                        ({ isIntersecting }) => isIntersecting
                    );

                    if (!entriesIntersecting.length) {
                        return;
                    }

                    entriesIntersecting.forEach(({ target }) => {
                        const index = parseInt(
                            target.getAttribute(PRODUCT_SLIDER_SLIDE_ATTRIBUTE),
                            10
                        );

                        const product = this.productsLoaded[index];

                        if (this.debouncedOnProductView) {
                            this.debouncedOnProductView({ product, index });
                        }

                        if (this.debouncedOnRecommendationView) {
                            this.debouncedOnRecommendationView({
                                product,
                                index,
                            });
                        }

                        this.productObserver.unobserve(target);
                    });
                },
                {
                    threshold: 0.5,
                }
            );

            const slides = this.$el.querySelectorAll(
                `[${PRODUCT_SLIDER_SLIDE_ATTRIBUTE}]`
            );

            slides.forEach(slide => {
                this.productObserver.observe(slide);
            });
        },
    },

    computed: {
        ...mapConfigGetters(['currency']),
    },

    beforeDestroy() {
        this.productObserver?.disconnect();
        this.productObserver = null;
    },

    methods: {
        async loadProductsSliderComponent() {
            try {
                const productsSliderComponent = await import(
                    /* webpackChunkName: "products-slider" */
                    /* webpackMode: "lazy" */
                    '@organisms/ProductsSlider/ProductsSlider'
                );

                this.productsSliderComponent = productsSliderComponent.default;
            } catch (err) {
                this.handleError();
                this.$errorHandler.captureDomainError(
                    PRODUCT_ERROR_DOMAIN,
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]: 'lazyload',
                    }
                );
            }
        },

        sendProductViewsToAnalytics(products, sliderList) {
            const productsToSend = products
                .flat()
                .map(({ product, index }) => {
                    const analyticsProduct = new DataLayerProduct({
                        product,
                        position: index + 1,
                    }).build();

                    const newAnalyticsProduct = mapProductToDataLayerProduct({
                        product,
                        index,
                    });

                    const mappedProduct =
                        analyticsProduct || newAnalyticsProduct;

                    return {
                        ...mappedProduct,
                        list: sliderList,
                    };
                })
                .filter(product => !!product);

            if (productsToSend.length) {
                this.$analytics.emit(IMPRESSION_VIEW, {
                    currency: this.currency,
                    products: productsToSend,
                    route: this.$route,
                });
            }
        },
    },
};
